@font-face {
    font-family: 'ScandinavianRegular';
    src: url('./fonts/ScandinavianNew-Regular.eot');
    src: url('./fonts/ScandinavianNew-Regular.eot?#iefix') format('embedded-opentype'), url('./fonts/ScandinavianNew-Regular.woff') format('woff'), url('./fonts/ScandinavianNew-Regular.woff2') format('woff2'), url('./fonts/ScandinavianNew-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'ScandinavianRegularBook';
    src: url('./fonts/ScandinavianNewBook-Regular.eot');
    src: url('./fonts/ScandinavianNewBook-Regular.eot?#iefix') format('embedded-opentype'), url('./fonts/ScandinavianNewBook-Regular.woff') format('woff'), url('./fonts/ScandinavianNewBook-Regular.woff2') format('woff2'), url('./fonts/ScandinavianNewBook-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'scandinavianblack';
    src: url('./fonts/ScandinavianNew-Black.eot');
    src: url('./fonts/ScandinavianNew-Black.eot?#iefix') format('embedded-opentype'), url('./fonts/ScandinavianNew-Black.woff') format('woff'), url('./fonts/ScandinavianNew-Black.woff2') format('woff2'), url('./fonts/ScandinavianNew-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'scandinavianbold';
    src: url('./fonts/ScandinavianNew-Bold.eot');
    src: url('./fonts/ScandinavianNew-Bold.eot?#iefix') format('embedded-opentype'), url('./fonts/ScandinavianNew-Bold.woff') format('woff'), url('./fonts/ScandinavianNew-Bold.woff2') format('woff2'), url('./fonts/ScandinavianNew-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
